import React, {FC} from 'react';
import Input from '../input';
import Button from '../button';
import './styles.scss';
interface IProps {
  btnName?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  className?: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  maxLength?: number;
}
const CustomInput: FC<IProps> = ({
  btnName,
  onClick,
  className,
  onChange,
  value,
  placeholder,
  disabled,
  required,
  error,
  maxLength
}) => {
  const handelKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onClick) onClick();
  };
  return (
    <div className="position-relative custom-input-button">
      <Input
        type="text"
        className={className}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        error={error}
        onKeyDown={handelKeyDown}
        maxLength={maxLength}
      />
      {onClick && (
        <div className="position-absolute btn-end">
          <Button onClick={onClick} disabled={disabled}>
            {btnName || 'Start'}
          </Button>
        </div>
      )}
    </div>
  );
};
export default CustomInput;
