import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/accessibility-audit/one.png';
import picture1 from '../../../images/tools/accessibility-audit/two.png';

const Info = () => (
  <div className="accessibility-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Is your website accessible to people with disabilities? </h4>
      <div className="mb-2">
        Web Content Accessibility Guidelines (WCAG) 2.1, WCAG 2.0 and IBM Accessibility standards provide
        recommendations for making the web more accessible to people with disabilities, including accommodations for
        blindness and low vision.
      </div>
      <div className="mb-4">
        Our free tool enables you to scan any web page to identify issues and recommendations for improving
        accessibility.
      </div>

      <div>
        <Button className="px-0" onClick={() => navigate('/registration')}>
          Sign up for Hexomatic account to increase your limits
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture1} alt="Grammar &amp; spelling page audit" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">Scan entire websites for accessibility problems</h4>
      <div className="mb-2">
        Upgrade to a paid Hexomatic plan to access our sitewide WCAG 2.1, WCAG 2.0 and IBM Accessibility audit featuring
        in-depth branded pdf reports.
      </div>
      <div className="mb-4">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready-made automations to
        perform spelling, grammar or accessibility audits in minutes and delegate time-consuming sales, marketing, or
        research tasks.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>
  </div>
);

export default Info;
