import React, {FC, useReducer, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrokenLinkCorrectorProps} from '../../types';
import classNames from 'classnames';
import './styles.scss';
import {minimizeIcon, plusIcon} from '../../images';

const FAQItem: FC<BrokenLinkCorrectorProps> = ({title, text}) => {
  const [isOpen, setIsOpen] = useReducer((val: boolean) => !val, false);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">
          <button
            className={classNames('faq_btn faq_btn_link', {
              collaped: !isOpen,
            })}
            onClick={setIsOpen}
          >
            {title}
            <span className="icon"> {isOpen ? minimizeIcon : plusIcon}</span>
          </button>
        </h5>
      </div>
      {isOpen && <div className="card-body">{text}</div>}
    </div>
  );
};

export default FAQItem;
