import React from 'react';

export const minimizeIcon = (
  <svg width="14" height="3" viewBox="0 0 14 3" fill="#1c1b1" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.915131" y="0.203613" width="13" height="2.25525" rx="1" fill="#1c1b1" />
  </svg>
);

export const plusIcon = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="#1c1b1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5588 6.12508C12.5588 6.70509 12.0887 7.17514 11.5084 7.17514H7.60884V11.0749C7.60884 11.655 7.13863 12.1251 6.5587 12.1249C6.26869 12.1249 6.00618 12.0076 5.81633 11.8176C5.62624 11.6274 5.50873 11.365 5.50886 11.0752L5.5087 7.17498H1.60867C1.31883 7.17498 1.05653 7.05762 0.866289 6.86738C0.676515 6.67761 0.559082 6.4151 0.559082 6.12508C0.558925 5.54518 1.02897 5.07503 1.60921 5.07503H5.50886V1.17523C5.50886 0.595142 5.97917 0.124939 6.55915 0.124939C7.13892 0.125096 7.60881 0.594828 7.60912 1.17484V5.07503H11.5092C12.089 5.07534 12.5585 5.54518 12.5588 6.12508Z"
      fill="#1c1b1"
    />
  </svg>
);

export const trialBadge = (
  <svg width="121" height="117" viewBox="0 0 121 117" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.286 0.764648L103.379 65.0186V95.0907L1.16699 0.764648H34.286Z" fill="#FFA500"/>
    <path d="M96.5867 88.8214L103.388 95.0874L96.5867 96.1074V92.5334V88.8214ZM1.23471 0.731445L6.21771 5.30145H2.17271H0.428711L1.23471 0.731445Z" fill="#D18700"/>
    <path d="M30.5891 18.8258L39.8355 15.3864L39.8853 15.3325L36.0813 11.8116L37.3415 10.45L42.8687 15.5658L41.5907 16.9466L32.3084 20.4171L30.5891 18.8258Z" fill="white"/>
    <path d="M44.0112 24.0359L42.8079 25.336L39.2154 22.0109L40.4187 20.7109L44.0112 24.0359Z" fill="white"/>
    <path d="M43.8998 31.3242C43.4511 30.9088 43.1514 30.4173 43.0009 29.8495C42.8553 29.2815 42.885 28.6733 43.0899 28.0251C43.2997 27.3766 43.7096 26.7228 44.3195 26.0638C44.9461 25.3869 45.576 24.9249 46.2093 24.6779C46.845 24.4283 47.4468 24.3593 48.0149 24.4708C48.5878 24.5822 49.091 24.8384 49.5244 25.2395C49.8551 25.5457 50.0786 25.8572 50.1947 26.1742C50.3157 26.4909 50.3671 26.7955 50.3487 27.088C50.3353 27.3803 50.2909 27.6391 50.2155 27.8646L50.2655 27.9108L53.0068 24.9491L54.6415 26.4622L47.3505 34.3395L45.735 32.8443L46.6108 31.8981L46.5416 31.834C46.3128 31.9269 46.0531 31.9889 45.7625 32.0199C45.4769 32.0507 45.1739 32.0154 44.8535 31.9141C44.5357 31.8151 44.2178 31.6185 43.8998 31.3242ZM45.6259 30.5009C45.89 30.7453 46.1796 30.88 46.4945 30.9049C46.8144 30.9296 47.1442 30.8563 47.4837 30.685C47.8258 30.516 48.1594 30.2558 48.4845 29.9045C48.8097 29.5532 49.0434 29.203 49.1857 28.8538C49.3279 28.5046 49.372 28.1741 49.3179 27.8622C49.2638 27.5503 49.1047 27.2721 48.8406 27.0276C48.5714 26.7784 48.2768 26.6415 47.9569 26.6167C47.637 26.592 47.3097 26.6652 46.9752 26.8364C46.6406 27.0076 46.3154 27.2637 45.9998 27.6047C45.6818 27.9483 45.448 28.2961 45.2984 28.648C45.1537 28.9998 45.1036 29.3343 45.148 29.6515C45.1974 29.9685 45.3567 30.2517 45.6259 30.5009Z" fill="white"/>
    <path d="M50.2792 37.2572C49.9022 36.9083 49.6268 36.532 49.453 36.1283C49.2815 35.7221 49.2297 35.3099 49.2976 34.8919C49.3705 34.4737 49.5873 34.0697 49.948 33.68C50.2518 33.3518 50.5672 33.1319 50.8942 33.0203C51.2212 32.9088 51.5514 32.8812 51.8849 32.9375C52.2184 32.9939 52.5493 33.1097 52.8775 33.2849C53.2082 33.4625 53.5328 33.6724 53.8513 33.9148C54.2281 34.1969 54.5359 34.4199 54.7746 34.5838C55.0157 34.745 55.2134 34.8375 55.3676 34.8612C55.5218 34.885 55.6571 34.834 55.7734 34.7083L55.7947 34.6853C56.0202 34.4417 56.1177 34.182 56.0873 33.9062C56.0594 33.6329 55.9057 33.3668 55.6262 33.1081C55.3313 32.8352 55.0362 32.6834 54.7408 32.6528C54.4477 32.6196 54.1828 32.6744 53.946 32.8171L52.5445 31.2913C52.9537 31.0035 53.3922 30.8333 53.8599 30.7806C54.33 30.7253 54.8085 30.7921 55.2955 30.981C55.7874 31.1697 56.2693 31.4824 56.7411 31.919C57.0693 32.2228 57.3478 32.552 57.5766 32.9066C57.808 33.2636 57.9656 33.6309 58.0494 34.0084C58.1358 34.3883 58.1295 34.7681 58.0304 35.1477C57.9338 35.5248 57.7228 35.889 57.3977 36.2403L53.7095 40.2251L52.1556 38.7869L52.9139 37.9676L52.8677 37.9249C52.6019 38.0217 52.3243 38.067 52.0348 38.0609C51.7476 38.0522 51.4557 37.9844 51.1591 37.8574C50.8648 37.7279 50.5715 37.5278 50.2792 37.2572ZM51.7951 36.5607C52.0361 36.7838 52.2928 36.9334 52.5653 37.0094C52.8401 37.0828 53.106 37.0861 53.363 37.0193C53.6201 36.9525 53.8447 36.8153 54.0369 36.6076L54.6172 35.9806C54.5351 35.9665 54.4361 35.932 54.3202 35.8771C54.2093 35.8221 54.088 35.7551 53.9564 35.6761C53.8272 35.5945 53.6991 35.5117 53.5722 35.4276C53.4477 35.3409 53.3347 35.2625 53.2331 35.1922C53.0146 35.0425 52.8051 34.9319 52.6046 34.8605C52.404 34.7891 52.2154 34.7692 52.0386 34.8008C51.8641 34.8298 51.7057 34.9212 51.5633 35.0751C51.3568 35.2982 51.2798 35.5434 51.3322 35.8109C51.3895 36.0782 51.5438 36.3281 51.7951 36.5607Z" fill="white"/>
    <path d="M53.7302 44.3577C53.5225 44.1654 53.343 43.9684 53.1918 43.7666C53.0408 43.5697 52.9289 43.3923 52.856 43.2344L54.3573 42.353C54.4951 42.59 54.6385 42.7822 54.7876 42.9297C54.9393 43.0797 55.1071 43.1684 55.2911 43.1959C55.4777 43.2257 55.6924 43.1816 55.935 43.0634L56.2626 42.9024L59.7681 34.8636L61.4913 36.4585L58.6987 41.9293L58.7602 41.9862L64.0106 38.7903L65.7453 40.3959L57.3899 44.817C56.9853 45.0329 56.579 45.171 56.1709 45.2313C55.763 45.2965 55.3561 45.2603 54.9502 45.1227C54.542 44.9877 54.1353 44.7327 53.7302 44.3577Z" fill="white"/>
    <path d="M72.2332 46.4008L71.094 47.6316L67.5361 44.3386L68.6753 43.1077L72.2332 46.4008ZM70.7931 42.4399L72.4317 43.9565L67.3337 49.4644C67.1937 49.6157 67.1076 49.755 67.0754 49.8824C67.0456 50.0071 67.0563 50.1242 67.1075 50.2335C67.1613 50.3451 67.2433 50.452 67.3536 50.554C67.4305 50.6252 67.5134 50.69 67.6022 50.7484C67.6933 50.8042 67.763 50.8473 67.8111 50.8775L66.9403 52.3354C66.8345 52.285 66.6918 52.2077 66.5123 52.1034C66.3303 52.0017 66.1293 51.8561 65.9094 51.6668C65.5003 51.3167 65.2027 50.9438 65.0168 50.5479C64.8335 50.1545 64.7773 49.7549 64.8481 49.3491C64.919 48.9434 65.1349 48.5481 65.4958 48.1632L70.7931 42.4399Z" fill="white"/>
    <path d="M67.8323 53.2967L73.3005 47.3887L74.8891 48.859L73.935 49.8898L73.9965 49.9467C74.4436 49.6797 74.8807 49.5701 75.3078 49.6179C75.7373 49.6631 76.1175 49.8388 76.4482 50.145C76.5303 50.2209 76.614 50.3079 76.6994 50.406C76.7848 50.5041 76.8551 50.5953 76.9103 50.6797L75.5646 52.1336C75.509 52.0393 75.4216 51.9203 75.3023 51.7766C75.183 51.6329 75.0669 51.5088 74.9541 51.4043C74.7131 51.1812 74.449 51.0345 74.162 50.964C73.8798 50.8933 73.5982 50.8992 73.3171 50.9818C73.0385 51.0667 72.7876 51.2297 72.5645 51.4708L69.4709 54.8132L67.8323 53.2967Z" fill="white"/>
    <path d="M72.2643 57.3987L77.7325 51.4907L79.371 53.0073L73.9028 58.9153L72.2643 57.3987ZM79.2605 51.491C79.0169 51.2655 78.8827 50.9913 78.8578 50.6684C78.8379 50.3453 78.9336 50.0696 79.1448 49.8414C79.3537 49.6157 79.6188 49.5016 79.9401 49.4991C80.2663 49.4964 80.5513 49.6077 80.7949 49.8332C81.0385 50.0587 81.1702 50.333 81.1901 50.6561C81.215 50.979 81.123 51.2533 80.9141 51.479C80.7029 51.7072 80.4342 51.8227 80.1079 51.8254C79.7866 51.8279 79.5041 51.7165 79.2605 51.491Z" fill="white"/>
    <path d="M76.7969 61.801C76.4199 61.4521 76.1445 61.0758 75.9707 60.6721C75.7992 60.2658 75.7474 59.8537 75.8153 59.4357C75.8882 59.0175 76.105 58.6135 76.4657 58.2237C76.7695 57.8955 77.0849 57.6756 77.4119 57.5641C77.7389 57.4526 78.0691 57.425 78.4026 57.4813C78.7361 57.5376 79.067 57.6534 79.3952 57.8286C79.7259 58.0062 80.0505 58.2162 80.369 58.4586C80.7458 58.7407 81.0536 58.9637 81.2923 59.1275C81.5334 59.2888 81.7311 59.3813 81.8853 59.405C82.0395 59.4287 82.1748 59.3777 82.2911 59.2521L82.3124 59.229C82.5379 58.9854 82.6354 58.7257 82.605 58.45C82.5771 58.1766 82.4234 57.9106 82.1439 57.6519C81.849 57.379 81.5539 57.2272 81.2585 57.1966C80.9654 57.1634 80.7005 57.2181 80.4637 57.3608L79.0622 55.8351C79.4714 55.5473 79.9099 55.377 80.3776 55.3243C80.8477 55.2691 81.3262 55.3359 81.8132 55.5247C82.3051 55.7134 82.787 56.0261 83.2588 56.4628C83.587 56.7666 83.8655 57.0958 84.0943 57.4504C84.3257 57.8074 84.4833 58.1746 84.5671 58.5522C84.6535 58.9321 84.6472 59.3118 84.5481 59.6915C84.4515 60.0686 84.2405 60.4327 83.9154 60.784L80.2272 64.7689L78.6733 63.3306L79.4316 62.5113L79.3854 62.4686C79.1196 62.5654 78.842 62.6108 78.5525 62.6047C78.2653 62.596 77.9734 62.5282 77.6768 62.4012C77.3825 62.2716 77.0892 62.0715 76.7969 61.801ZM78.3128 61.1045C78.5538 61.3276 78.8105 61.4771 79.083 61.5531C79.3578 61.6266 79.6237 61.6299 79.8807 61.5631C80.1378 61.4963 80.3624 61.359 80.5546 61.1513L81.1349 60.5244C81.0528 60.5102 80.9538 60.4757 80.8379 60.4209C80.727 60.3658 80.6058 60.2988 80.4741 60.2198C80.3449 60.1383 80.2168 60.0555 80.0899 59.9714C79.9654 59.8847 79.8524 59.8062 79.7508 59.736C79.5323 59.5862 79.3229 59.4756 79.1223 59.4043C78.9217 59.3329 78.7331 59.313 78.5563 59.3446C78.3818 59.3735 78.2234 59.465 78.081 59.6188C77.8745 59.8419 77.7975 60.0872 77.8499 60.3547C77.9072 60.622 78.0615 60.8719 78.3128 61.1045Z" fill="white"/>
    <path d="M90.4279 59.5847L83.137 67.462L81.4984 65.9455L88.7894 58.0681L90.4279 59.5847Z" fill="white"/>
  </svg>
);
