import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import AccessibilityAuditWrapper from '../../../components/tools-components/accessibility-audit';

const AccessibilityAudit = () => (
  <Layout>
    <PageHero
      title="Check any web page for accessibility issues"
      titleSmall
      subtitle="Audit any page in seconds for accessibility problems using the Web Content Accessibility Guidelines (WCAG) 2.1, WCAG 2.0 and IBM Accessibility standards."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <AccessibilityAuditWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 mt-4 text-center">Accessibility Audit FAQ</h4>
        <FAQItem
          title="What is an accessibility audit?"
          text={
            <>
              <p>
                An accessibility audit evaluates how accessible your organization's web pages are to everyone, including
                people with a range of disabilities. Audits check whether pages adhere to the Web Content Accessibility
                Guidelines (WCAG) standards and conform with ADA. A thorough accessibility audit allows you to detect
                any availability issue on time and be confident about your website.
              </p>
              <p>
                Moreover, the inclusion of accessibility into your website not only benefits people with disabilities,
                but also enhances its usability for everyone.
              </p>
            </>
          }
        />
        <FAQItem
          title="How often should you do an accessibility audit?"
          text={
            <p>
              Website accessibility audit is a continuous process. It is recommended to do a full website accessibility
              audit once a year. However, if you update your website often, more frequent accessibility audits will be
              necessary.
            </p>
          }
        />
        <FAQItem
          title="How long does an accessibility audit take?"
          text={
            <p>
              Our Hexomatic accessibility audit tool can check your website for accessibility in minutes. All you need
              to do is insert your website URL, select the accessibility audit standard and click Start.
            </p>
          }
        />
        <FAQItem
          title="What is accessibility certification?"
          text={
            <>
              <p>
                An accessibility certification indicates the level of compliance with the WCAG guidelines and Section
                508 standards (IBM accessibility).
              </p>
              <p>
                Using our Hexomatic accessibility audit tool you can check your website for WCAG 2.0, WCAG 2.1 standards
                and IBM accessibility.
              </p>
            </>
          }
        />
        <FAQItem
          title="Why should websites be accessible in the US?"
          text={
            <>
              <p>
                Almost all businesses in the United States have recognized that people with disabilities are not a minor
                part of the population. AS per the CDC’s Morbidity and Mortality Weekly Report, in the United States,
                one in four adults has a disability. From the market perspective, websites with accessibility issues
                siginificantly lose out on their business.
              </p>
              <p>
                However, this is not the only reason for websites to be accessible in the US. Website accessibility
                audit is not an optional means in the US. Moreover, you can be fined for not obeying the accessibility
                standards or creating a separate website for people with disabilities instead of making your website
                accessible to everyone.
              </p>
            </>
          }
        />
        <FAQItem
          title="When is it necessary to perform an accessibility audit?"
          text={
            <p>
              There is no fixed time for performing a website accessibility audit. However, the sooner you do it, the
              better for your website and your business. Additionally, it’s more cost-effective and less labor-intensive
              to perform an accessibility audit right before making your website public, than getting back to fix the
              detected issues after going live.
            </p>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default AccessibilityAudit;
