import React, {FC} from 'react';
import {InputTypes} from '../types';
import './style.scss';

const Input: FC<InputTypes> = ({
  type,
  value,
  placeholder,
  disabled,
  required,
  error,
  ref,
  label,
  className,
  leftIcon,
  rightIcon,
  onChange,
  onKeyDown,
  onKeyUp,
  id,
  name,
  defaultValue,
  dataUrl,
  maxLength,
}) => {
  return (
    <div className="custom-input">
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="input-wrapper">
        {leftIcon && <span className="icon left">{leftIcon}</span>}
        {rightIcon && <span className="icon right">{rightIcon}</span>}
        <input
          data-url={dataUrl}
          id={id || label || ''}
          ref={ref ? ref : null}
          type={type ? type : 'text'}
          placeholder={placeholder || ''}
          value={value}
          disabled={disabled ? true : false}
          required={required ? required : false}
          className={`input-field ${error ? 'error-border' : ''} ${leftIcon ? 'left-icon' : ''} ${
            rightIcon ? 'right-icon' : ''
          } ${className || ''}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          name={name}
          defaultValue={defaultValue}
          maxLength={maxLength}
        />
      </div>

      {error && <div
        className={`alert alert-danger mt-2 my-0 px-25 py-1 mx-auto ${error ? 'visible' : 'invisible'}`}
        role="alert"
      >
        {error || 'error'}
      </div>}
    </div>
  );
};

export default Input;
