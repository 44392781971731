import React from 'react';
import {Link, navigate} from 'gatsby';
// import FileSaver from 'file-saver';

import {cleanShortURL, makeValidHref} from '../../../helpers';
import {downlodIconBtn} from '../../../images/svgIcons';

const AccessibilityResult = (props: any) => {
  if (props.result._accessibility_audit_pdf_url[0] === 'Not found.')
    return (
      <div className="my-5 d-flex flex-column w-100">
        <span className="go-back" onClick={() => navigate(-1)}>
          ← Go Back
        </span>
        <div className="col-12 accessibility-result">
          <h5 className="text-center">Page not found</h5>
        </div>
      </div>
    );
  const handleClick = async () => {
    const url =
      props.result._accessibility_audit_pdf_url[0] && props.result._accessibility_audit_pdf_url[0].replace('///', '/');
    // const file = url && await fetch(url);
    // const blob = file && await file.blob();
    // blob && FileSaver.saveAs(blob, `${cleanShortURL(props.scanUrl || props.url)}-accessibility-audit.pdf`);
    url && window.open(url, '_blank', 'noopener');
  };

  return (
    <div className="m-auto" style={{maxWidth: '900px', width: '100%'}}>
      {props.url && (
        <div className="d-flex m-auto" style={{maxWidth: '785px', width: '90%'}}>
          <div>
            <Link to="/tools/history/#accessibility-audit">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            <a
              title={props.url}
              href={makeValidHref(props.url)}
              target="_blank"
              className="text-truncate"
              style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              rel='noopener'
            >
              {props.url}
            </a>
          </div>
        </div>
      )}
      <div className="m-auto accessibility-result-wrapper">
        <p className={`d-flex align-items-center justify-content-center download-btn  mt-2 mb-2`} onClick={handleClick}>
          Download full report {downlodIconBtn}
        </p>
        <div className="accessibility-result">
          {!props.result._accessibility_audit_pdf_url[0] ? (
            <h5 className="text-center">Page not found</h5>
          ) : (
            <div className="d-md-flex justify-content-between options text-center">
              <div className="accessibility-card">
                <div className="accessibility-value">{props.result._accessibility_audit_pass_count[0]}</div>
                <div className="accessibility-title rule">Rule passed</div>
              </div>
              <div className="accessibility-card">
                <div className="accessibility-value">{props.result._accessibility_audit_fail_count[0]}</div>
                <div className="accessibility-title fail">Fails</div>
              </div>
              <div className="accessibility-card">
                <div className="accessibility-value">{props.result._accessibility_audit_potential_count[0]}</div>
                <div className="accessibility-title potential">Potentials</div>
              </div>
              <div className="accessibility-card">
                <div className="accessibility-value">{props.result._accessibility_audit_manual_count[0]}</div>
                <div className="accessibility-title manual">Manuals</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessibilityResult;
