import React, {useState, useEffect, useCallback, useContext} from 'react';
import CustomInput from '../../custom-input';
import Info from './info';
import Result from './result';
import ProgressWithHint from '../../../components/progress-hint';
import {toolRequest, request} from '../../../requests';
import WebSocket from 'isomorphic-ws';
import {
  validURL,
  returnSocketLink,
  freeToolsErrors,
  accessibilityStandards,
  ERROR_MESSAGES,
} from '../../../helpers/constants';
import GoToLoginPage from '../tools-login';
import LimitReached from '../limit-modal';
import {UserContext, UserType} from '../../../helpers/contexts/user-context';
import Button from '../../button';
import Select from '../../../components/select';

import './styles.scss';
import {Link} from 'gatsby';
import {HexomaticUserType} from '../../../helpers/contexts/hexomatic-user';
import {useQuery} from '@apollo/react-hooks';
import {GET_HEXOMATIC_USER, ME} from '../../../graphql/queries';
import {getHintPopupPerTool} from '../../../helpers';

const AccessibilityAuditWrapper = () => {
  const hintName = 'free-tools-hint-accessibility';
  const {user} = useContext(UserContext);
  //----------------State----------------
  const [value, setValue] = useState('');
  const [socket, setSocket] = useState<any>(null);
  const [error, setError] = useState('');
  const [limit, setLimit] = useState('');
  const [socketId, setSocketId] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [standard, setStandard] = useState({label: 'IBM Accessibility', value: 'IBM_Accessibility'});
  const [requierd, setRequeird] = useState(false);
  const [clientId, setClientId] = useState('');

  const [mainUser, setUser] = useState<null | UserType>(null);
  const [hexomaticUser, setHexomaticUser] = useState<null | HexomaticUserType>(null);

  const {data: hexomaticUserData} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
    skip: !!getHintPopupPerTool(hintName),
  });

  useEffect(() => {
    if (hexomaticUserData && hexomaticUserData.HexomaticUser && hexomaticUserData.HexomaticUser.getByLegacyId) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
    if (hexomaticUserData && hexomaticUserData.User && hexomaticUserData.User.get) {
      setUser(hexomaticUserData.User.get);
    }
  }, [hexomaticUserData]);

  //----------useEffect-------------------
  useEffect(() => {
    socketStart();
  }, []);

  useEffect(() => {
    error === 'URL is not valid' && setError('');
  }, [value]);

  useEffect(() => {
    if (!socket) return;
    socket.onerror = function() {
      console.log('Connection Error');
    };

    socket.onopen = function() {
      console.log('WebSocket Client Connected');
    };

    socket.onclose = () => {
      socketStart();
    };

    return () => {
      socket.close();
    };
  }, [socket]);
  useEffect(() => {
    if (!socket) return;
    socket.onmessage = function(e: any) {
      const data = JSON.parse(e.data);
      if (data && data.clientId && data.clientId !== clientId) {
        setClientId(data.clientId);
        return;
      }
      if (data && data.error && freeToolsErrors.includes(data.error)) {
        setLimit(data.error);
        setShow(false);
        return;
      }
      if (data && data.socketId !== socketId) return;
      setResult(data);
      setShow(false);
      setSocketId('');
    };
  }, [socket, clientId, socketId]);
  //---------------Functions----------------------

  const handleClick = useCallback(async () => {
    if (!value || !value.trim()) {
      return;
    }
    if (!validURL(value)) {
      setError('URL is not valid');
      return;
    }
    if (!standard.value) {
      setRequeird(true);
      return;
    }
    setRequeird(false);
    setError('');
    setShow(false);
    setResult('');
    if (!getHintPopupPerTool(hintName) && user && user.id) {
      setShowCreditsModal(true);

      return;
    }
    try {
      setLoading(true);
      const res = await toolRequest('accessibility', value, {
        settings: standard.value,
        logo: '',
        selected: true,
        clientId,
        requestSource: 'free_tools',
      });
      if (res.status === 403) {
        setLimitReached(true);
        setLoading(false);
        setLoading(false);
        setSocketId('');
        return;
      }
      if (res?.data?.socketId) {
        setSocketId(res.data.socketId);
        setResult('');
        setShow(true);
      } else if (res.errorData) {
        if (ERROR_MESSAGES[res.errorData.message]) {
          setLimit(ERROR_MESSAGES[res.errorData.message]);
        } else {
          setError('Something went wrong. Please try again.');
        }
      }
      setLoading(false);
    } catch (error) {
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }, [value, standard, setError]);

  const socketStart = () => {
    //@ts-ignore
    const newSocket = new WebSocket(returnSocketLink());
    setSocket(newSocket);
  };

  const getResult = async () => {
    if (!socketId) return;
    try {
      //setLoading(true);
      const res: any = await request(`result/${socketId}`);
      if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.error &&
        freeToolsErrors.includes(res.data.result.error)
      ) {
        setLimit(res.data.result.error);
        setShow(false);
        setLoading(false);
        setSocketId('');
        return;
      }

      if (res && res.data && res.data.result && res.data.result._accessibility_audit_pdf_url) {
        setResult(res.data.result);
        setShow(false);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };
  const handleChangeModalState = () => setLimitReached(false);
  //-------------------------------------------------------------
  return (
    <div className="d-block m-auto px-2">
      {
        <div className="d-flex pb-2" style={{maxWidth: '785px', margin: 'auto'}}>
          <Link to="/tools" className="n-wrap">
            ← Back to tools
          </Link>
          <Link to="/tools/history/#accessibility-audit" className="n-wrap ml-auto">
            See history →
          </Link>
        </div>
      }
      <div className="mx-auto accessibility-wrapper mb-2">
        <CustomInput error={error} onChange={setValue} value={value} placeholder="Add URL" disabled={loading || show} />
        <div className="d-sm-flex align-items-end justify-content-between bottom-wrapper">
          <div className="select-std">
            <label className="mb-0">Select standard</label>
            <Select
              options={accessibilityStandards}
              onChange={setStandard}
              value={standard}
              disabled={loading}
              empty={requierd && !standard.value}
            />
          </div>
          <Button onClick={handleClick} disabled={loading || show}>
            Start
          </Button>
        </div>
      </div>
      {result && <Result result={result} scanUrl={value} />}
      <div className={`mx-auto col-12 my-1 ${socketId && !result ? 'visible' : 'invisible'}`}>
        {show && <ProgressWithHint func={getResult} />}
      </div>
      <Info />
      {limitReached && <GoToLoginPage toolName="Accessibility audit" setOpen={handleChangeModalState} />}
      {limit && <LimitReached text={limit} setLimit={setLimit} />}
      {showCreditsModal && (
        <LimitReached
          hint_name={hintName}
          defaultModal
          hideModal={setShowCreditsModal}
          automation_credits={5}
          confirm={handleClick}
          user={mainUser}
          hexomaticUser={hexomaticUser}
        />
      )}
    </div>
  );
};

export default AccessibilityAuditWrapper;
